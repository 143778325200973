@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes flashLight {
  50% {
    opacity: 0.2;
  }
}
@keyframes flashLight {
  50% {
    opacity: 0.2;
  }
}

.flashLight {
  -webkit-animation: flashLight 2s linear infinite;
          animation: flashLight 2s linear infinite;
}

body {
  color: #fff;
  background-color: #000;
  font-family: "Roboto", sans-serif;
}

footer {
  border-style: dotted !important;
}

footer ul {
  margin: 0;
  padding: 0;
}

footer ul li {
  display: inline;
  margin: 0;
  padding: 0;
  border-right: 1px dotted #ffc107;
}
footer ul li a {
  padding: 8px;
}

ul.vote {
  margin: 0;
  padding: 0;
  list-style: none;
}

.btn-facebook {
  background-color: #3b5998 !important;
}

