@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
body {
  color: #fff;
  background-color: #000;
  font-family: "Roboto", sans-serif;
}

footer {
  border-style: dotted !important;
}

footer ul {
  margin: 0;
  padding: 0;
}

footer ul li {
  display: inline;
  margin: 0;
  padding: 0;
  border-right: 1px dotted #ffc107;
}
footer ul li a {
  padding: 8px;
}

ul.vote {
  margin: 0;
  padding: 0;
  list-style: none;
}

.btn-facebook {
  background-color: #3b5998 !important;
}
